<template>
  <v-content style="position:relative;">
    <router-view style="paddingBottom:100px" />

    <dashboard-core-footer
      footerPosition="position:absolute;bottom:0;width: 100%;"
    />
  </v-content>
</template>

<script>
export default {
  name: 'DashboardCoreView',

  components: {
    DashboardCoreFooter: () => import('./Footer'),
  },
};
</script>
